.el-textarea .el-textarea__inner {
  min-height: 10px !important;
  height: 100px !important;
  resize: none;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.importAfterClassExercisesData .el-form-item__content {
  display: block !important;
}
.importAfterClassExercisesData .el-upload {
  border: 0;
  width: 100px;
  height: 36px;
}
.studentNum span {
  margin-right: 10px;
}
