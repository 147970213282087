













































































































































































































































































































































































.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }

    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }

    .el-radio__inner:hover {
      border-color: #5c6be8;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }

  .Batch {
    padding: 1rem 1rem;

    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #EBEEF5;
      border-radius: 5px;

      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .BatchBoxSecond {
        width: 100%;

        .BatchforBox {
          display: flex;
          flex-wrap: wrap;

          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;

            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
